import request from '../utils/request'
import baseURL from '../utils/config'

//物流订单取消原因配置-列表数据查询
export function wGetList(data) {
    return request({
        url: baseURL.baseURL + 'api/Adm_CancelType/GetList',
        method: 'post',
        data
    })
}

//GET 物流订单取消原因配置-设置是否启用
export function wSetEnable(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_CancelType/SetEnable',
      method: 'get',
      params:data
  })
}
//物流订单取消原因配置-新增或编辑
export function wAddOrEdit(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_CancelType/AddOrEdit',
      method: 'post',
      data
  })
}
//物流订单取消原因配置-删除
export function wDelete(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_CancelType/Delete',
      method: 'get',
      params:data
  })
}
//物流订单取消原因配置-导出
export function Export() {
  return request({
      url: baseURL.baseURL + 'api/Adm_CancelType/Export',
      method: 'get',
  })
}

//物流订单取消原因配置-得到详情
export function GetDetail() {
  return request({
      url: baseURL.baseURL + 'api/Adm_CancelType/GetDetail',
      method: 'get',
  })
}